/* eslint-disable react-hooks/exhaustive-deps */
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Slides } from "../components/hero/Slides";
import { Layout } from "../components/layout/Layout";
import { MdOutlineClose } from "react-icons/md";
import { Scrollspy } from "@makotot/ghostui";
import { IS_BROWSER } from "../utils";

const INostriPiatti = ({ data }) => {
  const { wpSezione, allWpCategoriaPiatti } = data;
  const { title, content, iNostriPiatti } = wpSezione;
  const { herogallery, opacity } = iNostriPiatti;
  const categories = allWpCategoriaPiatti.nodes;

  const [popupIndex, setPopupIndex] = useState(-1);
  const [hoveredTitle, setHoveredTitle] = useState(null);
  const [currentCategory, setCurrentCategory] = useState(-1);

  const mainSliderRef = useRef<HTMLDivElement>(null);
  const firstCategoryRef = useRef<HTMLDivElement>(null);
  const sectionRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ];

  const handlePopup = (index: number) => {
    if (popupIndex === index) {
      setPopupIndex(-1);
    } else {
      setPopupIndex(index);
    }
  };

  const handleChangedCategory = useCallback((index: number) => {
    setCurrentCategory(index);
  }, []);

  const handleScroll = useCallback(() => {
    if (IS_BROWSER && firstCategoryRef.current && mainSliderRef.current) {
      const scrolledAmount = Math.round(window.scrollY);
      const { offsetTop: firstCategoryTop } = firstCategoryRef.current;
      const { offsetHeight: sliderHeight } = mainSliderRef.current;

      if (scrolledAmount > firstCategoryTop) {
        firstCategoryRef.current.classList.add("sticky");
      }
      if (scrolledAmount < sliderHeight) {
        firstCategoryRef.current.classList.remove("sticky");
      }
    }
  }, [IS_BROWSER]);

  useEffect(() => {
    IS_BROWSER && window.addEventListener("scroll", handleScroll);
    return () => {
      IS_BROWSER && window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll, IS_BROWSER]);

  return (
    <main>
      <Helmet title="I Nostri Piatti - Ristorante A Mangiare" defer={false} />
      <Layout headerPosition="position-absolute" className="bg-white">
        <div ref={mainSliderRef}>
          <Slides
            title={title}
            content={content}
            opacity={opacity}
            images={herogallery}
          />
        </div>

        {/* BANNER */}
        <div ref={firstCategoryRef} className="nav d-none d-md-flex">
          <div
            style={{ height: "60px" }}
            className="bg-secondary position-absolute w-100"
          />
          <ul className="mx-auto text-center">
            {categories.map((category, index) => {
              const isCurrent = index === currentCategory;
              const { slug, categoriePiatti } = category;
              const { icon, color } = categoriePiatti;
              return (
                <li key={index} className="categories-item">
                  <a href={`#${slug}`}>
                    <div style={{ height: "100px" }} className="item-container">
                      <div
                        style={{
                          border: `3px solid #4d4749`,
                          position: "absolute",
                          height: "100px",
                          width: "100px",
                          backgroundColor: color,
                          opacity: isCurrent ? 1 : 0,
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          height: "100px",
                          width: "100px",
                          clip: !isCurrent
                            ? "rect(0px, 100px, 60px, 0ch)"
                            : "unset",
                        }}
                      >
                        <GatsbyImage
                          objectFit={"none"}
                          image={icon.localFile.childImageSharp.gatsbyImageData}
                          alt=""
                        />
                      </div>
                    </div>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>

        {/* CATEGORIES SLIDES */}
        <Scrollspy sectionRefs={sectionRefs} offset={-100}>
          {({ currentElementIndexInViewport }) => (
            <div>
              {categories.map((category, index) => {
                const { slug, name, description, piatti, categoriePiatti } =
                  category;
                const { menu, color, layout } = categoriePiatti;
                if (currentElementIndexInViewport === index) {
                  handleChangedCategory(index);
                }
                return (
                  <div
                    ref={sectionRefs[index]}
                    id={slug}
                    className="position-relative d-flex flex-column justify-content-center pt-5 pb-5"
                    style={{
                      backgroundColor: color,
                      minHeight: "800px",
                      height: "auto",
                      overflow: "hidden",
                    }}
                    key={slug}
                  >
                    <div
                      onClick={() => handlePopup(index)}
                      className="mx-auto p-2 cursor-pointer"
                      style={{
                        border: "5px solid white",
                        borderRadius: "15px",
                        width: "300px",
                      }}
                    >
                      <h2 className="text-uppercase text-white text-center">
                        {name}
                      </h2>
                    </div>

                    {popupIndex === index && (
                      <div
                        style={{
                          zIndex: 3,
                          minWidth: "55vw",
                          minHeight: "75vh",
                        }}
                        className="piatto-popup position-absolute top-50 start-50 translate-middle bg-dark d-flex"
                      >
                        <div className="container p-4 mb-5 align-self-center">
                          <h1 className="text-center text-white">{name}</h1>
                          <div
                            className="text-white h5"
                            dangerouslySetInnerHTML={{ __html: menu }}
                          />
                        </div>
                        <div
                          style={{
                            zIndex: -1,
                          }}
                          className="position-absolute left-0 bottom-0"
                        >
                          <GatsbyImage
                            alt={slug}
                            image={
                              layout.localFile.childImageSharp.gatsbyImageData
                            }
                          />
                        </div>
                        <button
                          style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            backgroundColor: "transparent",
                            border: "none",
                            zIndex: 2,
                          }}
                          onClick={() => handlePopup(-1)}
                        >
                          <MdOutlineClose size={32} color="white" />
                        </button>
                      </div>
                    )}

                    <div className="container">
                      <div className="row">
                        {piatti.nodes.map(piatto => {
                          const { slug, title, piattiCF } = piatto;
                          const { photo } = piattiCF;
                          const isHovered = hoveredTitle === slug;
                          return (
                            <div
                              onMouseOver={() => {
                                setHoveredTitle(slug);
                              }}
                              onMouseLeave={() => {
                                setHoveredTitle(null);
                              }}
                              style={{ transition: "all 3s ease" }}
                              className="col-auto mx-auto pb-5 pt-5 position-relative"
                              key={slug}
                            >
                              <GatsbyImage
                                alt={title}
                                image={
                                  photo.localFile?.childImageSharp
                                    .gatsbyImageData
                                }
                                style={{
                                  zIndex: 2,
                                }}
                              />
                              <div
                                style={{
                                  opacity: isHovered ? 1 : 0,
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor: "white",
                                    width: "255px",
                                    height: "255px",
                                    opacity: "0.6",
                                    borderRadius: "50%",
                                    zIndex: 2,
                                  }}
                                  className="position-absolute top-50 start-50 translate-middle"
                                ></div>
                                <div
                                  className="position-absolute top-50 start-50 translate-middle"
                                  style={{
                                    width: "93%",
                                    zIndex: 2,
                                  }}
                                >
                                  <h3 className="text-dark text-center cursor-default">
                                    {title}
                                  </h3>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div style={{ zIndex: 2 }} className="mx-auto container">
                      <h2 className="text-white text-center">{description}</h2>
                    </div>
                    <div
                      style={{
                        opacity: 0.4,
                        zIndex: 0,
                      }}
                      className="position-absolute left-0 bottom-0"
                    >
                      <GatsbyImage
                        alt={slug}
                        image={layout.localFile.childImageSharp.gatsbyImageData}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </Scrollspy>
      </Layout>
    </main>
  );
};

export const query = graphql`
  query {
    wpSezione(slug: { eq: "i-nostri-piatti" }) {
      title
      content
      iNostriPiatti {
        herogallery {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
        opacity
      }
    }
    allWpCategoriaPiatti(sort: { fields: categoriePiatti___order }) {
      nodes {
        slug
        name
        description
        piatti {
          nodes {
            slug
            title
            piattiCF {
              photo {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, height: 256)
                  }
                }
              }
            }
          }
        }
        categoriePiatti {
          color
          order
          menu
          icon {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, height: 100, width: 100)
              }
            }
          }
          layout {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, height: 400)
              }
            }
          }
        }
      }
    }
  }
`;

export default INostriPiatti;
